const sentryEventFilters = [
    ((event, hint) => {
        // Filter Google promise rejections.
        if(hint?.originalException?.message?.match?.(/Failed to fetch/)) {
            const nonGoogleFetchExceptions = (event.breadcrumbs ?? []).filter(crumb => {
                return (
                    (crumb?.level === 'error') &&
                    !(crumb?.data?.url ?? '').match(/https:\/\/google/)
                );
            })

            if(!nonGoogleFetchExceptions.length) {
                return null;
            }
        }
        return event;
    }),
]

export default sentryEventFilters;